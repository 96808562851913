import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import {
  formatDateToRead,
  handleGlobalUserControl,
  joinWithSeparator,
} from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "firstName",
    render: (row) => row?.firstName + " " + row?.lastName,
  },
  {
    title: "Client",
    field: "client",
    render: (row) => row?.client?.name,
  },
  { title: "Role", field: "role" },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Since",
    field: "dateCreated",
    render: (row) => (row.dateCreated ? formatDateToRead(row.dateCreated) : ""),
  },
  {
    title: "Created By",
    // field: "createdBy.firstName",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) =>
      joinWithSeparator(
        [row.createdBy?.firstName, row.createdBy?.lastName],
        " "
      ),
  },
];

const UsersList = () => {
  const headerActions = [
    {
      label: "Global Signout",
      iconName: "update",
      tooltip: "Signout ALL Users",
      onClick: () => handleGlobalUserControl({ selectAll: true }, ["iam"]),
      color: "primary",
    },
  ];

  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.users}
      headerTitle="Users"
      headerActions={headerActions}
      hideAction={{ delete: true, selection: true }}
      userFields={["$firstName", "$lastName"]}
      searchFields={["role", "phone", "email"]}
      withHook={true}
      mainQuery={{ populate: { path: "client", select: "name" } }}
    />
  );
};

export default UsersList;
