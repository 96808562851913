import commonConfig from "config/commonConfig";
import HTTP from "@ui/Utils/http";

const baseApiUrl = commonConfig.baseApiUrl;

export const clientWiseProductsCount = async () => {
  try {
    const url = baseApiUrl + "c/iam/clients/clientWiseProductsCount";

    const res = await HTTP.post(url);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const readDbStats = async () => {
  try {
    const url = baseApiUrl + "c/iam/clients/clusterStats";

    const res = await HTTP.post(url);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
