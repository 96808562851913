import MainSection from "@ui/MuiComponents/Section/MainSection";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaidIcon from "@mui/icons-material/Paid";
import HoverIcons from "@ui/assets/commonIcons/customIcons/HoverIcons";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import { useState } from "react";
import enums from "helpers/enums";
import { search } from "@ui/ComponentUtils/blueprintAPIs";
import { subscribeAppModule } from "views/Clients/api";
import Label from "@ui/components/Label";
import { formatDateToRead, handleGlobalUserControl } from "@ui/Utils/helper";
import RHDatePicker from "@ui/ReactHookFormControls/RHDatePicker";
import _ from "lodash";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DetailContent = ({ isEditable }) => {
  const { watch, reset, setValue } = useFormContext();
  const values = watch();
  const [appModules, setAppModules] = useState([]);
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const apps = await search(enums.models.appModules, {
          sort: { key: 1 },
        });
        setAppModules(apps || []);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    if (values.code) {
      (async () => {
        try {
          // if (values.premiumClient?.divisionControl) {
          const res = await search(enums.models.divisions, {
            client: values._id,
          });
          setDivisions(
            res?.length ? res : [{ _id: "default", name: "Default" }]
          );
          // } else {
          // setDivisions([{ _id: "default", name: "Default" }]);
          // }
        } catch (err) {}
      })();
    }
  }, [values.premiumClient?.divisionControl, values.code]);

  const handleSubscribe = async (appModule, clientId) => {
    try {
      const clientData = await subscribeAppModule(appModule, clientId);

      setValue("appModules", clientData.appModules);
    } catch (err) {
      console.log(err);
    }
  };

  const renderAppConfig = (app) => {
    const key = app.key;

    if (key === "data-engine") {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <pre>{`{ Prime && Diva } Config`}</pre>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {divisions.length ? (
                divisions.map((div, divisionIndex) => (
                  <Fragment key={divisionIndex}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        backgroundColor: "lightgrey",
                        fontWeight: "bold",
                        margin: 10,
                        padding: 8,
                      }}
                    >
                      <Grid item xs={8}>
                        {div.name}
                      </Grid>
                      <Grid item xs={4}>
                        <RHCheckbox
                          name={`appConfig.${key}.divisionLevel.[${div._id}].integrateWithPrime`}
                          label="Integrate with prime"
                          disabled={!isEditable}
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.integrateWithPrime
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={2}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.host`}
                          placeholder={"12.240.16.178"}
                          label="Host"
                        />
                      ) : (
                        <Label
                          label="Host"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.primeIntegration?.host
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.database`}
                          label="Database"
                          placeholder="data-engine"
                        />
                      ) : (
                        <Label
                          label="Database"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.primeIntegration?.database
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.username`}
                          label="Username"
                          placeholder="admin"
                        />
                      ) : (
                        <Label
                          label="Username"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.primeIntegration?.username
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.password`}
                          label="Password"
                        />
                      ) : (
                        <Label
                          label="Password"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.primeIntegration?.password
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={2}>
                      {isEditable ? (
                        <RHAutoComplete
                          name={`appConfig.${key}.divisionLevel.[${div._id}].defaultStockStatus`}
                          label="Default Stock Status"
                          options={Object.values(enums.stockStatus)}
                        />
                      ) : (
                        <Label
                          label={"Default Stock Status"}
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.defaultStockStatus
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={2}>
                      <RHCheckbox
                        name={`appConfig.${key}.divisionLevel.[${div._id}].diamondEnabled`}
                        label="Diamonds"
                        disabled={!isEditable}
                        value={
                          values.appConfig?.[key]?.divisionLevel?.[div._id]
                            ?.diamondEnabled
                        }
                      />
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <pre>
                          SELECT * FROM source WHERE{" "}
                          {values.appConfig?.[key]?.divisionLevel?.[div._id]
                            ?.primeIntegration?.sourceTableFilter?.field ||
                            "_ _ _"}{" "}
                          == '
                          {values.appConfig?.[key]?.divisionLevel?.[div._id]
                            ?.primeIntegration?.sourceTableFilter?.value ||
                            "_ _ _"}
                          '
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        {isEditable ? (
                          <RHTextField
                            name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.sourceTableFilter.field`}
                            placeholder="Source Table Filter Field"
                          />
                        ) : null}
                      </Grid>

                      <Grid item xs={3}>
                        {isEditable ? (
                          <RHTextField
                            name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.sourceTableFilter.value`}
                            placeholder="Source Table Filter Field"
                          />
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <pre style={{ width: "100%" }}>
                          {`SELECT * from (SELECT * FROM stage EXCEPT SELECT * FROM copy) s ${`WHERE ${
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.primeIntegration?.stageTableFilter?.field ||
                            "_ _ _"
                          }= '${
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.primeIntegration?.stageTableFilter?.value ||
                            "_ _ _"
                          }'`}`}
                        </pre>
                      </Grid>
                      <Grid item xs={3}>
                        {isEditable ? (
                          <RHTextField
                            name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.stageTableFilter.field`}
                            placeholder="Stage Table Filter Field"
                          />
                        ) : null}
                      </Grid>

                      <Grid item xs={3}>
                        {isEditable ? (
                          <RHTextField
                            name={`appConfig.${key}.divisionLevel.[${div._id}].primeIntegration.stageTableFilter.value`}
                            placeholder="Stage Table Filter Field"
                          />
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid item xs={8}>
                      <b>Diva Integration</b>
                    </Grid>
                    <Grid item xs={4}>
                      <RHCheckbox
                        name={`appConfig.${key}.divisionLevel.[${div._id}].integrateWithDiva`}
                        label="Integrate with Diva"
                        disabled={!isEditable}
                        value={
                          values.appConfig?.[key]?.divisionLevel?.[div._id]
                            ?.integrateWithDiva
                        }
                      />
                    </Grid>

                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].divaIntegration.email`}
                          label="Email"
                        />
                      ) : (
                        <Label
                          label="Email"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.divaIntegration?.email
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].divaIntegration.password`}
                          label="Password"
                        />
                      ) : (
                        <Label
                          label="Password"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.divaIntegration?.password
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].divaIntegration.vendorId`}
                          label="Vendor ID"
                        />
                      ) : (
                        <Label
                          label="Vendor ID"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.divaIntegration?.vendorId
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].divaIntegration.apiUrl`}
                          label="Diva API URL"
                          placeholder="http://123.45.678.90:1400/"
                        />
                      ) : (
                        <Label
                          label="Diva API URL"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.divaIntegration?.apiUrl
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      {isEditable ? (
                        <RHDatePicker
                          name={`appConfig.${key}.divisionLevel.[${div._id}].divaIntegration.fromDate`}
                          label="Sync Quotations From"
                        />
                      ) : (
                        <Label
                          label="Sync Quotations From"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.divaIntegration?.fromDate &&
                            formatDateToRead(
                              values.appConfig?.[key]?.divisionLevel?.[div._id]
                                ?.divaIntegration?.fromDate,
                              "yyyy-MM-DD"
                            )
                          }
                        />
                      )}
                    </Grid>
                  </Fragment>
                ))
              ) : (
                <Typography>No Divisions Found</Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    } else if (
      key === "e-creatives" ||
      (values.superClient && (key === "sheen-op" || key === "iris"))
    ) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {isEditable ? (
              <RHTextField
                name={`appConfig.${key}.oneSignalAppId`}
                label="One Signal App ID"
              />
            ) : (
              <Label
                label="One Signal App ID"
                value={values.appConfig?.[key]?.oneSignalAppId}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {isEditable ? (
              <RHTextField
                name={`appConfig.${key}.onesignalRestApiKey`}
                label="One Signal REST API Key"
              />
            ) : (
              <Label
                label="One Signal REST API Key"
                value={values.appConfig?.[key]?.onesignalRestApiKey}
                valueStyle={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}
              />
            )}
          </Grid>

          <Grid item xs={4}>
            <RHCheckbox
              name={`appConfig.${key}.stockWiseWorkflow`}
              label={"StockWise (default: CatalogueWise)"}
              disabled={!isEditable}
              value={values.appConfig?.[key]?.showInStockOnly}
            />
          </Grid>

          <Grid item xs={4}>
            {isEditable ? (
              <RHTextField
                name={`appConfig.${key}.bundleIdentifier`}
                label="Bundle Identifier"
              />
            ) : (
              <Label
                label="Bundle Identifier"
                value={values.appConfig?.[key]?.bundleIdentifier}
              />
            )}
          </Grid>

          <Grid item xs={4}>
            {isEditable ? (
              <RHTextField
                name={`appConfig.${key}.shareApp.android`}
                label="Playstore App URL"
              />
            ) : (
              <Label
                label="Playstore App URL"
                value={values.appConfig?.[key]?.shareApp?.android}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {isEditable ? (
              <RHTextField
                name={`appConfig.${key}.shareApp.ios`}
                label="Appstore App URL"
              />
            ) : (
              <Label
                label="Appstore App URL"
                value={values.appConfig?.[key]?.shareApp?.ios}
              />
            )}
          </Grid>
        </Grid>
      );
    } else if (key === "chitthi") {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <pre>{`{ InvoiceReport && Templates } Config`}</pre>
          </AccordionSummary>
          <AccordionDetails>
            {divisions.length ? (
              divisions.map((div, index) => {
                return (
                  <Grid container spacing={3} key={index}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: "lightgrey",
                        fontWeight: "bold",
                        margin: 10,
                        padding: 8,
                      }}
                    >
                      {div.name}
                    </Grid>
                    <Grid item xs={4}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].irPrefix`}
                          label="I/R Prefix"
                        />
                      ) : (
                        <Label
                          label="I/R Prefix"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.irPrefix
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {isEditable ? (
                        <RHTextField
                          name={`appConfig.${key}.divisionLevel.[${div._id}].irStart`}
                          label="I/R Start #"
                          type="number"
                        />
                      ) : (
                        <Label
                          label="I/R Start #"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[div._id]
                              ?.irStart
                          }
                        />
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      {isEditable ? (
                        <RHAutoComplete
                          options={[
                            "Template1",
                            "Template2",
                            "Template3",
                            "Template4",
                            "Template5",
                            "Template6",
                            "Template7",
                            "Template8",
                            "Template9",
                            "Template10",
                          ]}
                          name={`appConfig.${key}.divisionLevel.[${div._id}].availableTemplates`}
                          label="Available Templates"
                          multiple={true}
                          onChange={(_, v) => {
                            if (
                              v &&
                              v[0] &&
                              !values.appConfig?.[key]?.divisionLevel?.[div._id]
                                ?.defaultTemplate
                            ) {
                              setValue(
                                `appConfig.${key}.divisionLevel.[${div._id}].defaultTemplate`,
                                v[0]
                              );
                            }
                          }}
                        />
                      ) : (
                        <Label
                          label="Available Templates"
                          value={
                            values.appConfig?.[key]?.divisionLevel?.[
                              div._id
                            ]?.availableTemplates?.join(", ") || ""
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <h4>
                  Add Divisions for this.client in Data Engine to continue
                </h4>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {values.code &&
        appModules.map((app, i) => {
          const subscribed = values.appModules?.find(
            (module) => module?._id === app?._id
          );

          return (
            <MainSection
              key={i}
              title={app?.name}
              style={{ marginBottom: "24px", height: "100%" }}
              actions={[
                {
                  buttonType: "icon",
                  color: "default",
                  customIcon: (
                    <HoverIcons
                      muiIcon={subscribed ? <PaidIcon /> : <AttachMoneyIcon />}
                      muiIconHover={
                        subscribed ? <AttachMoneyIcon /> : <PaidIcon />
                      }
                    />
                  ),
                  tooltip: subscribed ? "UnSubscribe" : "Subscribe",

                  onClick: () =>
                    handleSubscribe({ _id: app._id, key: app.key }, values._id),
                },
                {
                  buttonType: "icon",
                  color: "default",
                  customIcon: (
                    <HoverIcons
                      muiIcon={<ToggleOnIcon />}
                      muiIconHover={<ToggleOffOutlinedIcon />}
                    />
                  ),
                  tooltip: `Signout ALL Users from ${app.name}`,
                  hidden:
                    isEditable ||
                    !Object.keys(enums.appModules).find(
                      (key) => key === app.key
                    ),
                  onClick: async () => {
                    try {
                      await handleGlobalUserControl(
                        {
                          selectAll: true,
                        },
                        [app.key]
                      );

                      window.location.reload();
                    } catch (err) {
                      console.log(err);
                    }
                  },
                },
              ]}
            >
              {renderAppConfig(app)}
            </MainSection>
          );
        })}
    </div>
  );
};

export default DetailContent;
