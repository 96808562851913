import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";

const baseApiUrl = commonConfig.baseApiUrl;
const modelName = "iam/clients";

export const subscribeAppModule = async (appModule, clientId) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + "c/iam/clients/subscribeAppModule",
      { appModule, clientId }
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
