import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, joinWithSeparator } from "@ui/Utils/helper";

const columns = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "module",
    field: "module",
  },
  {
    title: "Since",
    field: "dateCreated",
    render: (row) => (row.dateCreated ? formatDateToRead(row.dateCreated) : ""),
  },
  {
    title: "Created By",
    // field: "createdBy.firstName",
    sorting: false,
    render: (row) =>
      joinWithSeparator(
        [row.createdBy?.firstName, row.createdBy?.lastName],
        " "
      ),
  },
];

const JavaScriptsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.javascripts}
      headerTitle="JavaScripts"
      hideAction={{ delete: true, selection: true }}
      searchFields={["name"]}
      mainQuery={{
        populate: [{ path: "createdBy", select: "firstName lastName" }],
      }}
    />
  );
};

export default JavaScriptsList;
