import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { search } from "@ui/ComponentUtils/blueprintAPIs";

const earthErrorMessage =
  "Country, States, City options failed to load. Reload to retry";

const DetailHeader = ({ isEditable, onSubmit, setIsEditable }) => {
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const params = useParams();
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleEarth = async () => {
    try {
      const tempCountries = ((await search(enums.models.countries)) || []).map(
        (country) => country["name"]
      );
      setCountries(tempCountries);
    } catch (err) {
      CustomEventEmitter.emit("alert_error", earthErrorMessage);
    }
  };

  useEffect(() => {
    handleEarth();
  }, []);

  useEffect(() => {
    if (values.address?.country) {
      (async () => {
        try {
          const tempStates = (
            (await search(enums.models.states, {
              "country.name": values.address?.country,
            })) || []
          ).map((state) => state["name"]);

          setStates(tempStates);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.address?.country]);

  useEffect(() => {
    if (values?.address?.state) {
      (async () => {
        try {
          const tempCities = (
            (await search(enums.models.cities, {
              "country.name": values.address?.country,
              "state.name": values?.address?.state,
            })) || []
          ).map((city) => city["name"]);

          setCities(tempCities);
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.address?.state]);

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    {
      name: "subDomain",
      label: "Sub Domain",
      fieldType: "textField",
    },
    {
      name: "logo",
      label: "Logo",
      fieldType: "upload",
      model: enums.models.clients,
      additionalPath: "logos",
      accept: "image/*",
      single: true,
      previewThumbnail: true,
      required: true,
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
      dataType: enums.dataTypes.number,
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },

    {
      label: "Premium Features",
      name: "premiumClient",
      fieldType: enums.fieldTypes.featureCheckbox,
      features: [
        {
          label: "Pricing",
          name: "pricing",
        },
        {
          label: "Division Control",
          name: "divisionControl",
        },
        {
          label: "Unlimited Bucket Storage",
          name: "unlimitedBucketStorage",
        },
      ],
    },

    {
      name: "address.country",
      label: "Country",
      fieldType: enums.fieldTypes.dropdown,
      options: countries,
      render: "address.country",
      autoComplete: false,
    },
    {
      name: "address.state",
      label: "State",
      fieldType: enums.fieldTypes.dropdown,
      options: states,
      render: "address.state",
      autoComplete: false,
      readOnly: isEditable && !values.address?.country,
    },
    {
      name: "address.city",
      label: "City",
      fieldType: enums.fieldTypes.dropdown,
      options: cities,
      render: "address.city",
      autoComplete: false,
      readOnly: isEditable && !values.address?.state,
    },

    {
      label: "Street",
      name: "address.street",
      fieldType: enums.fieldTypes.textField,
    },

    {
      label: "Pincode",
      name: "address.pincode",
      fieldType: enums.fieldTypes.textField,
    },

    {
      label: "Business Type",
      name: "businessType",
      fieldType: enums.fieldTypes.dropdown,
      options: Object.values(enums.businessType),
    },
  ];

  const links = [
    {
      name: "Clients",
      url: "#/" + enums.models.clients,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: () => handleCancel(),
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/clients");
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  return (
    <>
      <Header links={links} pageTitle={"Clients"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
