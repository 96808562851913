import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useAuth } from "@ui/components/AuthProvider";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";

import { useState } from "react";
import moment from "moment";
import Button from "components/Button";
import { generateBackup } from "./api";
import { paginate } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
// Make it easy to handle PAID and UnPaid Members

const StyledButton = styled(Button)`
  height: 15vh;
  width: 10vw;
  font-size: 28px;
`;

const BackupDetail = () => {
  const [backupRecords, setBackupRecords] = useState([]);

  const takeBackup = async () => {
    try {
      const res = await generateBackup();
      CustomEventEmitter.emit("alert_success", res?.data?.message);

      window.location.reload();
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit("alert_error", err?.message);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await paginate(enums.models.backups);
      setBackupRecords(res || []);
    })();
  }, []);

  return (
    <Grid
      style={{ marginTop: "40px" }}
      container
      direction="column"
      //   alignItems="center"
      //   justifyContent="center"
      spacing={5}
    >
      <Grid item>
        <StyledButton onClick={takeBackup}>Insta Backup</StyledButton>
      </Grid>

      {backupRecords?.length && <h1 style={{ margin: "8px" }}>History...</h1>}
      <table>
        {backupRecords.map((rec) => (
          <>
            <th>
              <td colSpan={3}>
                {moment(rec?.dateCreated).format("DD MMM YYYY")}
              </td>
            </th>

            {rec?.logs?.map(
              (log) =>
                log && (
                  <tr>
                    <td>{log.fileName}</td>
                    <td>
                      <a href={log.location} target="_blank">
                        Click here
                      </a>
                    </td>
                  </tr>
                )
            )}
          </>
        ))}
      </table>
    </Grid>
  );
};

export default BackupDetail;
