import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "@ui/components/AuthProvider";

import {
  createRecord,
  getByCode,
  search,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import _ from "lodash";

import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import enums from "helpers/enums";
import Emitter from "@ui/Utils/CustomEventEmitter";

const entitySchema = {
  name: "",
};

const ScriptDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();

  const params = useParams();

  const auth = useAuth();
  const [clients, setClients] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      try {
        const res = await search(enums.models.clients);
        setClients(res || []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const code = params?.code;

      try {
        if (code !== "create") {
          const res = await getByCode(enums.models.javascripts, code);
          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.javascripts);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {};
      if (!values.code) {
        res = await createRecord(enums.models.javascripts, values);
        navigate(`/${enums.models.javascripts}/${res?.code}`);
      } else {
        res = await updateRecord(enums.models.javascripts, values);
      }

      formMethods.reset(res);
      setIsEditable(false);
      Emitter.emit("alert_success", "Successfull");
    } catch (err) {
      console.log(err);

      Emitter.emit(
        "alert_error",
        err?.response?.data?.message || "Something went wrong"
      );
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.id === "create") {
        navigate("/javascripts");
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    {
      name: "module",
      label: "Module",
      fieldType: enums.fieldTypes.dropdown,
      options: [
        "iam",
        "data-engine",
        "cortex",
        "e-creatives",
        "chitthi",
        "vendor-portal",
      ],
    },
    {
      name: "chunkSize",
      label: "Chunk Size",
      fieldType: enums.fieldTypes.textField,
    },
    {
      name: "clients",
      label: "Clients",
      fieldType: enums.fieldTypes.dropdown,
      options: clients,
      getOptionLabel: (opt) => opt.name || "",
      getOptionSelected: (opt, val) => opt?._id === val?._id,
      multiple: true,
      render: "name",
    },
  ];

  const links = [
    {
      name: "JavaScripts",
      url: "#/" + enums.models.javascripts,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Header links={links} pageTitle={"Script"} actions={actions} />

      <GeneralSection
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </FormProvider>
  );
};

export default ScriptDetail;
