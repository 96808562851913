import React from "react";
import ClientStockStats from "./Graphs/ClientStockStats";
import DashboardDBStats from "./Graphs/DBStats";

const DashboardList = () => {
  return (
    <div>
      <ClientStockStats />
      {/* <DashboardDBStats /> */}
    </div>
  );
};

export default DashboardList;
