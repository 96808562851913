import React from "react";
import enums from "helpers/enums";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import { formatDateToRead, joinWithSeparator } from "@ui/Utils/helper";

const columns = [
  { title: "Name", field: "name" },
  {
    title: "Subscribed Modules",
    field: "appModules.length",
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Since",
    field: "dateCreated",
    render: (row) => (row.dateCreated ? formatDateToRead(row.dateCreated) : ""),
  },
  {
    title: "Created By",
    // field: "createdBy.firstName",
    sorting: false, // due to invalid createdBy.firstName old data
    render: (row) =>
      joinWithSeparator(
        [row.createdBy?.firstName, row.createdBy?.lastName],
        " "
      ),
  },
];

const ClientsList = () => {
  return (
    <GeneralListPage
      columns={columns}
      model={enums.models.clients}
      headerTitle="Clients"
      hideAction={{ delete: true, selection: true }}
      searchFields={["name", "phone", "email"]}
    />
  );
};

export default ClientsList;
