import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { clientWiseProductsCount } from "../../api";
import {
  defaultGoldHex,
  formatNumberToRead,
  formatWeight,
} from "@ui/Utils/helper";

const ClientStockStats = () => {
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [isSunburst, setIsSunburst] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await clientWiseProductsCount();
        setData(res);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length && chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);

      const formattedData = data.map((client) => {
        const color = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
          Math.random() * 256
        )}, ${Math.floor(Math.random() * 256)})`;
        return {
          name: client.clientName,
          value: client.productCount,
          // uniqueDesignsCount: client.uniqueDesignsCount,
          grossWeight: client.totalGrossWeight,
          netWeight: client.totalNetWeight,
          itemStyle: {
            color,
          },
        };
      });

      const initialOption = {
        tooltip: {
          trigger: "item",
          // Designs: <b>${formatNumberToRead(
          //   params.uniqueDesignsCount
          // )} styles</b><br />
          formatter: function (params) {
            return `
            <div style="width: ${
              params.name?.toString().length > 18 ? "250px" : "180px"
            }; text-align: left; white-space: nowrap; padding: 8px;">
              <center><strong style="color: ${defaultGoldHex}">${
              params.name
            }</strong></center><br />            
              <div style="display: flex; justify-content: space-between;">
                <div><b>${formatWeight(params.data.grossWeight)}</b></div>
                <div><b>Gross</b></div>
              </div>
              <div style="display: flex; justify-content: space-between;">                
                <div><b>${formatWeight(params.data.netWeight)}</b></div>
                <div><b>Net</b></div>
              </div>

              <br />
              <center><b>${formatNumberToRead(
                params.value
              )} SKUs</b></center>               
            </div>
          `;
          },
        },
        series: [
          {
            type: "pie",
            data: formattedData,
            radius: [0, "90%"],
            label: {
              formatter: "{b}",
              fontSize: 12,
              overflow: "truncate",
              ellipsis: "...",
              minAngle: 3,
              rich: {
                small: {
                  fontSize: 10,
                  color: "#000",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: 3,
                  padding: [2, 4],
                },
              },
            },
            labelLine: {
              length: 10,
              length2: 10,
              smooth: true,
            },
          },
        ],
      };

      const totalOption = {
        tooltip: {
          trigger: "item",

          // LETS DO DESIGN LATER
          // Designs: <b>${formatNumberToRead(
          //   params.uniqueDesignsCount
          // )} styles</b><br />
          formatter: function (params) {
            return `
            <div style="width: ${
              params.name?.toString().length > 18 ? "250px" : "180px"
            }; text-align: left; white-space: nowrap; padding: 8px;">
              <center><strong style="color: ${defaultGoldHex}">${
              params.name
            }</strong></center><br />            
              <div style="display: flex; justify-content: space-between;">
                <div><b>${formatWeight(params.data.grossWeight)}</b></div>
                <div><b>Gross</b></div>
              </div>
              <div style="display: flex; justify-content: space-between;">                
                <div><b>${formatWeight(params.data.netWeight)}</b></div>
                <div><b>Net</b></div>
              </div>

              <br />
              <center><b>${formatNumberToRead(
                params.value
              )} SKUs</b></center>               
            </div>
          `;
          },
        },
        series: [
          {
            type: "sunburst",
            data: [
              {
                name: selectedClient ? selectedClient.name : "#family",
                value: selectedClient
                  ? selectedClient.value
                  : data.reduce((acc, client) => acc + client.productCount, 0),
                // uniqueDesignsCount: selectedClient
                //   ? selectedClient.uniqueDesignsCount
                //   : data.reduce(
                //       (acc, client) => acc + client.uniqueDesignsCount,
                //       0
                //     ),
                grossWeight: selectedClient
                  ? selectedClient.grossWeight
                  : data.reduce(
                      (acc, client) => acc + client.totalGrossWeight,
                      0
                    ),
                netWeight: selectedClient
                  ? selectedClient.netWeight
                  : data.reduce(
                      (acc, client) => acc + client.totalNetWeight,
                      0
                    ),
                children: [
                  {
                    name: "#family",
                    value: data.reduce(
                      (acc, client) => acc + client.productCount,
                      0
                    ),
                    // uniqueDesignsCount: selectedClient
                    //   ? selectedClient.uniqueDesignsCount
                    //   : data.reduce(
                    //       (acc, client) => acc + client.uniqueDesignsCount,
                    //       0
                    //     ),
                    grossWeight: data.reduce(
                      (acc, client) => acc + client.totalGrossWeight,
                      0
                    ),
                    netWeight: data.reduce(
                      (acc, client) => acc + client.totalNetWeight,
                      0
                    ),

                    itemStyle: {
                      color: `rgb(${Math.floor(
                        Math.random() * 256
                      )}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
                        Math.random() * 256
                      )})`, // Random color for inner circles
                    },
                    children: [],
                  },
                ],
              },
            ],
            radius: [0, "90%"],
            label: {
              rotate: "radial",
              formatter: function (params) {
                return params.name === "#family" ? params.name : "";
              },
              fontSize: 12,
              overflow: "truncate",
              ellipsis: "...",
              rich: {
                small: {
                  fontSize: 10,
                  color: "#000",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: 3,
                  padding: [2, 4],
                },
              },
            },
            labelLine: {
              length: 10,
              length2: 10,
              smooth: true,
            },
            emphasis: {
              focus: "ancestor",
            },
            minAngle: 3,
          },
        ],
      };

      const handleClientClick = (params) => {
        if (params.name === "#family") {
          setIsSunburst(false);
          setSelectedClient(null);
          chartInstance.setOption(initialOption);
        } else {
          setIsSunburst(true);
          setSelectedClient(params.data);
          chartInstance.setOption(totalOption);
        }
      };

      chartInstance.setOption(isSunburst ? totalOption : initialOption);

      chartInstance.on("click", handleClientClick);

      const handleResize = () => {
        chartInstance.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        chartInstance.dispose();
      };
    }
  }, [data, isSunburst, selectedClient]);

  return <div ref={chartRef} style={{ width: "100%", height: "750px" }}></div>;
};

export default ClientStockStats;
